const data = [
  {
    label: '2024 - Authored And Published A Research Paper Titled “An Analyitical Survey of Text Summarization Techniques” In IEMTRONICS (Scopus Indexed, SCImago Indexed)',
    value: 'View Publication',
    link: 'https://iemtronics.org/wp-content/uploads/2024/04/IEMTRONICS-2024-Conference-Proceedings-1.pdf',
  },
  {
    label: '2023 - National Winner in Smart India Hackathon, Organized by Govt. of India',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_smartindiahackathon-sih2023-teamgeek6-activity-7144994433355198464-ajIw?utm_source=share&utm_medium=member_desktop',
  },
  {
    label: '2023 - Authored And Published A Research Paper Titled “Application Of AI In Prevention And Awareness Of Domestic Violence” In IJSRED Volume 5 - Issue 5 [IJSRED-V5I5P63]',
    value: 'View Publication',
    link: 'http://www.ijsred.com/volume5-issue5-part7.html',
  },
  {
    label: '2023 - Second Position at SparkHack 7.0 of the Jadavpur University Convolution',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_hello-everyone-im-glad-to-announce-that-activity-7061234284430327808--WFu?utm_source=share&utm_medium=member_desktop',
  },
  {
    label: '2023 - Awarded National Winner In HackBrewer, Competing Against 700+ Participants',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_hackathon-winners-code-activity-7037406219279499264-z5EP?utm_source=share&utm_medium=member_desktop',
  },
  {
    label: '2023 - Earned National Winner Honour In CodeRush, Organized By The ByteMakers Club, With 400+ Participants',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_hackathon-winners-code-activity-7037406219279499264-z5EP?utm_source=share&utm_medium=member_desktop',
  },
  {
    label: '2023 - Received Best Open Innovation Award In DUHacks, A National-Level Hackathon That Attracted Over 1200 Participants',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_hackathon-winners-code-activity-7037406219279499264-z5EP?utm_source=share&utm_medium=member_desktop',
  },
  {
    label: '2023 - Achieved All India Top 10 In MozoHacks 4.0, Hosted By SRM University And Drawing In 560+ Participants',
    value: 'Click to View',
    link: 'https://www.linkedin.com/posts/aritraghosh1905_hackathon-winners-code-activity-7037406219279499264-z5EP?utm_source=share&utm_medium=member_desktop',
  },
];

export default data;
