const courses = [
  {
    title: 'Google Data Analytics Professional Certificate',
    link: 'https://www.coursera.org/account/accomplishments/professional-cert/PXCJATHC6PKN?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=prof',
    university: 'Google',
  },
  {
    title: 'AWS Fundamentals',
    link: 'https://www.coursera.org/account/accomplishments/specialization/TYCHRYHJGX37?utm_source=link&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=s12n',
    university: 'AWS',
  },
  {
    title: 'IBM Machine Learning Specialization',
    link: 'https://coursera.org/share/ec0f7730b3ee69e7171277ef8e062f53',
    university: 'IBM',
  },
  {
    title: 'Prompt Engineering',
    link: 'https://coursera.org/share/62d10b6e22129d11468ff60a87c7d149',
    university: 'Vanderbilt University',
  },
  {
    title: 'Microsoft Azure Specialization',
    link: 'https://coursera.org/share/8e260da80376dd4ac464ed5434886e30',
    university: 'Microsoft',
  },
  {
    title: 'Data Analytics for Machine Learning',
    link: 'https://www.coursera.org/account/accomplishments/certificate/V95BESF3CYK6',
    university: 'IBM',
  },
  {
    title: 'Building Transformer Based NLP Applications',
    link: 'https://drive.google.com/file/d/1dru8SFi1zhS4WfKAiMmHjVZ1tqN35nR4/view?usp=sharing',
    university: 'NVIDIA',
  },
  {
    title: 'Blockchain Specialization',
    link: 'https://www.coursera.org/account/accomplishments/specialization/certificate/DYXE4F77UUNS',
    university: 'Coursera (University at Buffalo)',
  },
];

export default courses;
