import React from 'react';
import { Link } from 'react-router-dom';

import Main from '../layouts/Main';

const Index = () => (
  <Main
    description="Aritra Ghosh - MoE's IIC | SIH'23 Winner | Ex-Intern at @CareEdge Group | 5x Hackathon Winner| Artificial Intelligence | Machine Learning | Fullstack Web Development | TSoC'23 | Pre-Final Year CS Undergrad (IEM'25)"
  >
    <article className="post" id="index">
      <header>
        <div className="title">
          <h1><Link to="https://aritraghosh.xyz">Hello There!</Link></h1>
        </div>
      </header>
      <p> Welcome to my website!
        You can read more {' '}{' '}<Link to="https://aritraghosh.xyz/about" style={{ fontWeight: 'bold', color: 'black' }}>about me</Link>{' '}{' '}
        or you can check out my {' '}
        <Link to="https://aritraghosh.xyz/resume" style={{ fontWeight: 'bold', color: 'black' }}>resume</Link>{' '}{' '},
        <Link to="https://aritraghosh.xyz/projects" style={{ fontWeight: 'bold', color: 'black' }}>projects</Link>{' '}
        view <Link to="https://aritraghosh.xyz/stats" style={{ fontWeight: 'bold', color: 'black' }}>my achievements</Link>{' '}
        or <Link to="https://aritraghosh.xyz/contact" style={{ fontWeight: 'bold', color: 'black' }}>contact</Link> me.
      </p>
      <p>I am always on the lookout for opportunities to collaborate and learn.</p>
    </article>
  </Main>
);

export default Index;
