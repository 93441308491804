// TODO Add a couple lines about each project
const data = [
  {
    title: 'Advocate.ai',
    link: 'https://advocate-ai.streamlit.app/',
    image: '/images/projects/aritra-ghosh-advocate.webp',
    date: '2024-01-04',
    desc:
      'Developed a conversational intelligence AI Legal Chatbot using LlamaIndex, LangChain, and RAG-based models, providing  answers to legal questions, boosting public legal rights awareness. Contains features like automatic document generation, multilingual translation, legal summarization, real-time lawyer location retrieval, and a Previous Judgement Library with 10,000+ cases. ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ '
      + 'Technologies Used - LLM, RAG, LlamaIndex, Langchain, Python, Django, FatsAPI, OpenAI, OpenAI Enbeddings, Pypdf, Tendorflow, Streamlit',
  },
  {
    title: 'Vitality.ai',
    link: 'https://vitality-ai.vercel.app/',
    image: '/images/projects/aritra-ghosh-vitality.webp',
    date: '2024-03-07',
    desc:
      'Vitality.ai is an AI-driven analysis tool that leverages computer vision and machine learning algorithms; delivered tailored feedback on chronic pain management and posture optimization, enhancing user outcomes significantly. It understands the reson for users Chronic pain and automatically provides excerises that the user can perform in fromt of their webcam; AI will then correct their posture by providing insigts in the form of weekly reports. ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎  '
      + 'Technologies Used - Python, Tensorflow, P5.js, Deep Learning, PoseNet, OpenCV',
  },
  {
    title: 'one.edu',
    link: 'https://aritra-ghosh.co/one.edu/',
    image: '/images/projects/aritra-ghosh-dashboard.webp',
    date: '2023-02-08',
    desc:
      'One.edu is a platform that can help students better manage their studies with an interactive dashboard and by providing them incentives for doing assignments on time, attending classes regularly and solving doubts of fellow batchmates. '
      + 'Technologies Used - React, Node, Express, MongoDB, HTML, CSS, JavaScript, Php, Netlify',
  },
  {
    title: 'Resume Scanner',
    link: 'https://resume-rater.streamlit.app/',
    image: '/images/projects/aritra-ghosh-resumerater.webp',
    date: '2023-11-16',
    desc:
      'An online applicant tracking system that matches the familiarity of an uploaded resume document to the provided job description and outputs a percentage score using OpenAI and Langchain and Hosted on Streamlit. '
      + 'Technologies Used - Python, Langchain, OpenAI, Streamlit, LLM, NLP',
  },
  {
    title: 'Summarizer',
    link: 'https://summarizze.streamlit.app/',
    image: '/images/projects/aritra-ghosh-summarizze.webp',
    date: '2023-18-10',
    desc:
      'An app to summarize long texts and documents.The app is built using "txtai" a powerful NLP library. Txtai builds embeddings databases, which are a union of vector indexes and relational databases. This enables similarity search with SQL. Embeddings databases can stand on their own and/or serve as a powerful knowledge source for large language model (LLM) prompts. '
      + 'Technologies Used - Python, Langchain, OpenAI, Streamlit, LLM, NLP',
  },
  {
    title: 'Kanya.io',
    link: 'https://aritra-ghosh.co/kanya/',
    image: '/images/projects/aritra-ghosh-kanya.webp',
    date: '2023-02-26',
    desc:
      'Kanya is a comprehensive and all inclusive platform which aims at supporting, uplifting and developing women of all ages, backgrounds and classes for ensuring that they receive financial assistance, legal support, medical aid and prenatal care through our web-application. '
      + 'Technologies Used - React, Node, HTML, CSS, JavaScript, Php, Vercel, Appwrite',
  },
  {
    title: 'Plagiarism Checker',
    link: 'https://plagiarismchecker.thecleveridiot.repl.co/',
    image: '/images/projects/aritra-ghosh-plagcheck.webp',
    date: '2023-02-26',
    desc:
      'A web-app that can detect plagiarism within text, providing a sentence-wise report with percentage of plagiarism and the link of the possible article from which the text is plagiarised.  '
      + 'Technologies Used - CSS, PIP, JavaScript, Python, Flask, REGEX, Beautiful-Soup, HTML5, Requests',
  },
  {
    title: 'Matrix Vision',
    link: 'https://aritra-ghosh.co/matrix_vision/matrix.html',
    image: '/images/projects/aritra-ghosh-mvision.webp',
    date: '2023-03-15',
    desc:
      'Ever wondered how would you look like in the matrix world?? Well now you can see yourself in it by using this tool. REQUIRES ACCESS TO YOUR CAMERA. '
      + 'Technologies Used - JavaScript, P5.Js, HTML/CSS',
  },
  {
    title: 'Roundtable',
    link: 'https://qclone.pages.dev/',
    image: '/images/projects/aritra-ghosh-quora-clone.webp',
    date: '2023-01-27',
    desc:
      'A platform that fosters connection and learning by enabling users to ask questions, answer them, and interact with other users to share knowledge and information on a wide range of topics. Equipped with a answer voting system and a categorical arrangement of topics. '
      + 'Technologies Used - React, Node, Nodemon, MongoDB, Postman, HTML, CSS, JavaScript, Php, Netlify',
  },
  {
    title: 'Portfolio Website',
    link: 'https://aritra-ghosh.co/',
    image: '/images/projects/aritra-ghosh-portfolio.webp',
    date: '2022-05-19',
    desc:
      '[NOT UPDATED TO CURRENT CV] Just a Simple portfolio website to showcase my work and to test my understanding of JavaScript. '
      + 'Technologies Used - HTML, CSS, JS',
  },
  {
    title: 'Luffy Wars',
    link: '',
    image: '/images/projects/aritra-ghosh-pygame_luffy.webp',
    date: '2023-01-23',
    desc:
      'An interactive game made using the pygame library in python. '
      + 'Technologies Used - Pyhton, Pygame, Replit',
  },
  {
    title: 'Gesture Controls',
    image: '/images/projects/aritra-ghosh-gesture-logo.webp',
    date: '2023-04-06',
    desc:
      'Remove the need of a touchsceen or a mouse, directly control your PC with hand gestures. '
      + 'Technologies Used - Python, JuputerNotebook, OpenCV, Pandas, Seaborn, Tensorflow, Keras',
  },
  {
    title: 'Senmeter',
    image: '/images/projects/aritra-ghosh-senmeterlogo.webp',
    date: '2023-04-06',
    desc:
      'A tool that uses AI to detect depression from the user\'s online presence. '
      + 'Technologies Used - Python, JuputerNotebook, Pandas, Wordblob, Seaborn, Tensorflow, Keras',
  },
  {
    title: 'Bios Engine',
    image: '/images/projects/aritra-ghosh-biosengine.webp',
    date: '2022-10-29',
    desc:
      'Predicting a players perfoence in upcoming matches based on a number of factors like previous performance, home ground advantage etc. '
      + 'Technologies Used - Python, JuputerNotebook, Pandas, Seaborn, Tensorflow, Keras',
  },
];

export default data;
